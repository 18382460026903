@import '~bulma/sass/utilities/_all';

$primary-dark: #388e3c;
$primary: #66bb6a;
$primary-light: #c8e6c9;
$primary-invert: #ffc400;

$secondary-dark: #0086ab;
$secondary: #42aac7;
$secondary-light: #97d3e3;

$table-striped-row-even-background-color: $primary-light;
$table-striped-row-even-hover-background-color: $primary-light;

$subject-colors: (
  japanese: red,
  math: skyblue,
  english: #fc0,
  science: green,
  society: orange,
  music: #10a,
  art: pink,
  health_physical: purple,
  tech_home: brown,
);

$header-size: 72;

$body-size: 16px;
$body-family: -apple-system, blinkmacsystemfont, roboto, 'Segoe UI', 'Helvetica Neue', helveticaneue, yugothic, 'Yu Gothic Medium', 'Yu Gothic', verdana, meiryo, sans-serif;

$animation-speed: (
  standard: 200ms ease-in-out,
);

@function px-to-rem($px) {
  @return $px / 16 + rem;
}

@function spacing($size) {
  @return px-to-rem($size * 8);
}

.detail-form {
  .columns {
    .label {
      font-size: px-to-rem(12);
      font-weight: normal;
    }
  }

  .horizontal-date {
    display: flex;
    align-items: center;
    margin-top: spacing(1);

    .label {
      margin-right: spacing(2);
      margin-bottom: 0;
      font-weight: normal;
      line-height: 1;

      & + * {
        flex: 1;
      }
    }

    input {
      padding: spacing(2);
    }
  }
}

.text-field-outlined {
  .MuiInputBase-root {
    input {
      box-sizing: border-box;
      height: 40px;
    }

    fieldset {
      border: 1px solid #dbdbdb;
    }

    &:hover {
      fieldset {
        border: 1px solid #b5b5b5;
      }
    }

    &.Mui-error {
      fieldset {
        border-color: #f44336;
      }
    }
  }
}

.modal-box {
  width: 100%;
  max-width: 600px;
  margin: spacing(10) auto 0;
  background-color: #fff;
  border-radius: 4px;

  .modal-inner {
    padding: spacing(2);

    & > div {
      max-height: 560px;
      overflow: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(#fff, 0.1);
        border: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    table {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      table-layout: fixed;

      th {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: small;
        color: gray;
        text-align: center;
        border: solid lightgray 1.5px;

        &.th-item {
          width: 60%;
        }

        &.th-tax {
          width: 10%;
        }

        &.th-amount {
          width: 30%;
        }

        &.th-remove {
          width: 10%;
          border: none;
        }
      }

      td {
        padding: 5px 10px;
        border: solid lightgray 1.5px;

        input {
          width: 100%;
          border-bottom: dotted gray 1px;

          &:focus {
            outline: none;
          }
        }

        &.td-item {
          width: 50%;
        }

        &.td-tax {
          width: 10%;
          text-align: center;

          .checkbox {
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }
        }

        &.td-amount {
          width: 30%;

          input {
            text-align: right;
          }
        }

        &.td-remove {
          width: 10%;
          padding: 5px 0 0;
          text-align: center;
          border: none;

          svg {
            font-size: 25px;
            color: gray;
          }
        }
      }
    }

    .field {
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: visible;

      .label {
        font-size: small;
        color: gray;
      }
    }

    .btn-area {
      text-align: center;
    }
  }
}
