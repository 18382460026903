@import 'src/assets/scss/_theme';

.detail-form.work-shift-detail {
  .staff-image-container {
    display: block;
    width: 90px;
    max-width: 90px;
    margin: 10px;
    text-align: center;

    .staff-image {
      position: relative;
      display: inline-block;
      width: 70px;
      min-width: 70px;

      .shift-number {
        position: absolute;
        top: -6px;
        right: -12px;
        z-index: 3;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        font-size: 16px;
        line-height: 0;
        color: white;
        background: black;
        border-radius: 50%;
      }

      .image {
        width: 100%;
        height: 70px;
        margin: auto;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .staff-name {
      text-align: center;
    }
  }

  .field {
    margin-bottom: 10px;

    .select-add-teacher {
      min-width: 210px;
    }
  }

  .shift-table {
    th {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        width: 10%;
      }

      &:nth-of-type(2) {
        width: 15%;
      }

      &:nth-of-type(4) {
        width: 5%;
      }

      &:nth-of-type(5) {
        width: 20%;
      }

      &:nth-of-type(6) {
        width: auto;
      }
    }

    .select-seat {
      min-width: 140px;
    }

    .select-teacher {
      min-width: 210px;
    }

    .shift-subject-separate-container {
      height: 66px;
      min-height: 66px;

      .subject-box {
        margin-top: 10px;
      }

      .shift-subject-separate-no-content {
        margin-top: 18px;
      }
    }
  }

  .works-table {
    /* stylelint-disable-next-line */
    th {
      &:nth-of-type(1),
      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7),
      &:nth-of-type(8) {
        width: 10%;
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(8),
      &:nth-of-type(9) {
        width: 8%;
      }

      &:nth-of-type(10) {
        width: auto;
        min-width: 200px;
      }
    }
  }

  /* stylelint-disable-next-line */
  .subject-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 35px;
    padding: 10px;
    margin: auto;
    color: #666;
    background: #d8efff;
    border-radius: 5px;
  }

  // TODO 将来的に振替の場合に色を変えるため、色変えstyleは参考程度に残しておく（+色指定のリストをもらい次第class名やstyleを修正する）
  .subject-box-japanese {
    color: map-get($subject-colors, japanese);
    background: #ffcdcd;
  }

  .subject-box-math {
    color: map-get($subject-colors, math);
    background: #004c5c;
  }

  .subject-box-english {
    color: map-get($subject-colors, english);
    background: #fff7f7;
  }

  .subject-box-science {
    color: map-get($subject-colors, science);
    background: #a6ffa6;
  }

  .subject-box-society {
    color: map-get($subject-colors, society);
    background: #e2ffdc;
  }

  .btn-area {
    margin: spacing(-2);
    text-align: center;

    .button {
      margin: spacing(2);
    }
  }
}
